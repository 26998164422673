import React from "react"

import Layout from "../components/layout"
import Article from "../components/News/article"

class ArticlePage extends React.Component {
    render() {
        const { location } = this.props
        const id = this.props["*"]
        return (
            <Layout location={location}>
                <Article id={id} />
            </Layout>
        )
    }
}

export default ArticlePage
