import React from "react"
import axios from "axios"

import Loader from "../Loader"
import Message from "../Message"
import SEO from "../SEO"
import Heading from "../Page/heading"
import Date from "../Page/date"
import Content from "../Page/content"

const articleAPI = process.env.API_ARTICLE

class Article extends React.Component {

    constructor(props) {
        super(props)
        this.state = { 
            article: {},
            isLoading: true,
            message: undefined, 
        }
    }

    componentDidMount = () => {
        const {id} = this.props
        this.getArticle(id)
    }
        
    getArticle = id => {
        axios.get(`${articleAPI}/${id}`)
        .then(response => {
            this.setState({ 
                article: response.data,
                isLoading: false
            })
        })
        .catch(error => {
            this.setState({
                message: {
                    type: "danger",
                    text: error.response.data.message
                },
                isLoading: false
            })
        })
    }

    render() {
        const {isLoading, message, article} = this.state
        if(isLoading === true) {
            return(
                <Loader />
            )
        } else if(message) {
            return(
                <Message type={message.type} message={message.text} />
            )
        } else {
            return(
                <>
                <SEO title={article.title} />
                <Heading title={article.title} />
                <Date date={article.date} />
                <Content content={article.content} />
                </>
            )
        }
    }
}

export default Article